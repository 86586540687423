









import { Component, Vue } from "vue-property-decorator";

@Component
export default class HelpPage extends Vue {
  mounted(): void {
    // From testing, without a brief timeout, it won't work.
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  }
  scrollFix(hashbang: string): void {
    location.hash = hashbang;
  }
}
